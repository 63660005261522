import { useIntl } from 'react-intl';

import { formatValueWithUnit } from '@/helpers/utils';

function useFormatProductAttributeValue() {
  const { formatNumber } = useIntl();

  function formatValue(value, unit) {
    if (typeof value !== 'number') {
      return formatValueWithUnit(value, unit);
    } else {
      return formatValueWithUnit(formatNumber(value), unit);
    }
  }

  return formatValue;
}

export default useFormatProductAttributeValue;
