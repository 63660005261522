import { Box } from '@mui/material';
import NextImage from 'next/image';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Slider from 'react-slick';

import Chip from '@/components/ui/Chip/Chip';
import useFormatMessage from '@/hooks/useFormatMessage';

import { imagesPropTypes } from '../propTypes';
import {
  ActionArea,
  Arrow,
  ArrowsWrapper,
  ImagePlaceholder,
  MainImageWrapper,
  ThumbnailImageWrapper,
} from './Images.style';
import Modal from './Modal/Modal';

const Images = ({ images = [], showChip = false }) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const formatMessage = useFormatMessage();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: false,
  };

  return (
    <>
      <div>
        <Box position="relative">
          {images?.length ? (
            <ActionArea onClick={() => setDialogOpen(true)}>
              <MainImageWrapper variant="outlined">
                <ArrowsWrapper>
                  <Arrow top />
                  <Arrow />
                </ArrowsWrapper>
                {images?.[activeImageIndex]?.url ? (
                  <NextImage
                    data-cy="big-image"
                    src={images?.[activeImageIndex]?.url}
                    alt=""
                    fill
                    sizes="(max-width: 900px) 90vw, (max-width: 1200px) 50vw, 600px"
                    style={{ objectFit: 'scale-down' }}
                    draggable={false}
                  />
                ) : (
                  <ImagePlaceholder />
                )}
              </MainImageWrapper>
            </ActionArea>
          ) : (
            <MainImageWrapper variant="outlined">
              <ImagePlaceholder />
            </MainImageWrapper>
          )}
          {showChip && (
            <Chip
              color="success"
              uppercase
              sx={{ position: 'absolute', left: 16, top: 16 }}>
              {formatMessage('rental_price_sale')}
            </Chip>
          )}
        </Box>
        {images?.length > 1 && (
          <Box
            mx={-0.75}
            mt={1.5}
            sx={{
              '& .slick-dots': {
                marginTop: 0,
              },
            }}>
            <Slider {...settings}>
              {images?.map(({ url, id }, index) => (
                <Box key={id} px={0.75}>
                  <ThumbnailImageWrapper
                    elevation={0}
                    variant="outlined"
                    active={index === activeImageIndex}>
                    <ActionArea onClick={() => setActiveImageIndex(index)}>
                      {url ? (
                        <NextImage
                          src={url}
                          alt=""
                          fill
                          sizes="200px"
                          style={{ objectFit: 'cover' }}
                          draggable={false}
                        />
                      ) : (
                        <ImagePlaceholder />
                      )}
                    </ActionArea>
                  </ThumbnailImageWrapper>
                </Box>
              ))}
            </Slider>
          </Box>
        )}
      </div>
      {dialogOpen && (
        <Modal
          onClose={() => setDialogOpen(false)}
          images={images}
          activeImageIndex={activeImageIndex}
        />
      )}
    </>
  );
};

Images.propTypes = {
  images: imagesPropTypes,
  showChip: PropTypes.bool,
};

export default Images;
