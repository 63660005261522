import { Close as CloseIcon } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { Stack } from '@mui/material';
import MuiModal from '@mui/material/Modal';
import NextImage from 'next/image';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import useFormatMessage from '@/hooks/useFormatMessage';

import ImageWithLoader from '../ImageWithLoader/ImageWithLoader';
import {
  Arrow,
  ArrowWrapper,
  CloseButton,
  ImageOuterWrapper,
  ImageWrapper,
  SlidesThumbnail,
  SlidesThumbnailImageWrapper,
  SlidesThumbnails,
} from './Modal.style';

const Modal = ({ onClose, images, activeImageIndex }) => {
  const [activeIndex, setActiveIndex] = useState(activeImageIndex);
  const formatMessage = useFormatMessage();

  const lastImageIndex = images.length - 1;

  const handleNext = () => {
    if (activeIndex < lastImageIndex) {
      setActiveIndex(prev => prev + 1);
    }
  };

  const handlePrev = () => {
    if (activeIndex > 0) {
      setActiveIndex(prev => prev - 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    trackTouch: true,
  });

  const handleClose = e => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <MuiModal data-cy="image-modal" open>
      <Stack
        position="relative"
        alignItems="center"
        direction="row"
        height="100%"
        onClick={handleClose}>
        <CloseButton
          data-cy="close-button"
          aria-label={formatMessage('product_details_page_modal_cancel')}
          onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        {images.length > 1 && (
          <ArrowWrapper onClick={handlePrev} prev>
            <Arrow component={ArrowBackIcon} disabled={activeIndex === 0} />
          </ArrowWrapper>
        )}
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: '100%', width: '100%' }}
          onClick={handleClose}
          {...handlers}>
          {images.map(({ url }, index) => (
            <ImageOuterWrapper
              key={index}
              data-cy="image-outer-wrapper"
              active={activeIndex === index}
              next={index >= activeIndex}>
              <ImageWrapper>
                <ImageWithLoader
                  data-cy="loaded-image"
                  loaderSize="7vh"
                  data-image-state={
                    activeIndex === index ? 'active' : 'inactive'
                  }
                  src={url}
                  alt=""
                  fill
                  sizes="(max-width: 600px) 100vw, (max-width: 1200px) 80vw, 1200px"
                  style={{ objectFit: 'scale-down' }}
                  draggable={false}
                />
              </ImageWrapper>
            </ImageOuterWrapper>
          ))}
        </Stack>
        {images.length > 1 && (
          <>
            <ArrowWrapper onClick={handleNext}>
              <Arrow
                component={ArrowForwardIcon}
                disabled={activeIndex === lastImageIndex}
              />
            </ArrowWrapper>
            <SlidesThumbnails>
              {images.map(({ url }, index) => (
                <SlidesThumbnail key={index}>
                  <SlidesThumbnailImageWrapper
                    onClick={() => setActiveIndex(index)}
                    active={activeIndex === index}>
                    <NextImage
                      data-cy={`thumbnail-${index}`}
                      src={url}
                      alt=""
                      fill
                      sizes="160px"
                      style={{ objectFit: 'cover' }}
                      draggable={false}
                    />
                  </SlidesThumbnailImageWrapper>
                </SlidesThumbnail>
              ))}
            </SlidesThumbnails>
          </>
        )}
      </Stack>
    </MuiModal>
  );
};

Modal.propTypes = {
  activeImageIndex: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

export default Modal;
