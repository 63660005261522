import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { RENTAL_PRICE_BASIS_TRANSLATION_KEYS } from '@/constants/rentalPrice';
import useFormatMessage from '@/hooks/useFormatMessage';
import useFormatPrice from '@/hooks/useFormatPrice';

const Price = ({ price, priceBasis, initialPrice }) => {
  const formatMessage = useFormatMessage();
  const formatPrice = useFormatPrice();

  if (!price) {
    return (
      <Typography component="div" color="primary.main" variant="h5Redesign">
        {formatMessage('rental_price_placeholder')}
      </Typography>
    );
  }

  return (
    <div>
      <Stack
        direction="row"
        alignItems="baseline"
        columnGap={1}
        flexWrap="wrap">
        {initialPrice && (
          <Typography
            data-cy="initial-price"
            component="div"
            color="grayscale.500"
            variant="h6Redesign"
            sx={{ textDecoration: 'line-through' }}>
            {formatMessage('rental_price_from', {
              price: formatPrice(initialPrice),
            })}
          </Typography>
        )}
        <Box>
          <Typography
            data-cy="price"
            component="span"
            color="primary.main"
            variant="h5Redesign">
            {formatMessage('rental_price_from', {
              price: formatPrice(price),
            })}
          </Typography>
          <Typography
            data-cy="price-basis"
            component="span"
            typography="body1Redesign"
            color="primary.main"
            ml={1}>
            {`${formatMessage(
              priceBasis
                ? RENTAL_PRICE_BASIS_TRANSLATION_KEYS[priceBasis]
                : 'rental_price_basis_day'
            )}*`}
          </Typography>
          <Typography
            data-cy="netto"
            component="span"
            color="text.disabled"
            variant="captionRedesign">{` ${formatMessage(
            'product_details_page_price_net'
          )}`}</Typography>
        </Box>
      </Stack>
    </div>
  );
};

Price.propTypes = {
  price: PropTypes.number,
  priceBasis: PropTypes.string,
  initialPrice: PropTypes.number,
};

export default Price;
